<template>
  <div>
    <div v-if="!isMobile">
      <div v-for="(item, index) in itemso" :key="index">
        <transtationdetial
          :item="item"
          :ref="'deo' + index"
          @video_runpage="video_runpage"
        ></transtationdetial>
      </div>
    </div>
    <!-- // 手机版 -->
    <div v-if="isMobile">
      <div v-for="(item, index) in itemso" :key="index">
        <mobidetiali
          :item="item"
          :ref="'deo' + index"
          @video_runpage="video_runpage"
        ></mobidetiali>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import transtationdetial from "@/components/pc/detialitems";

import p1 from "@/assets/images/transtation/P1/p1.mp4";
import mp1 from "@/assets/images/transtation/P1/mp1.jpg";
import p2 from "@/assets/images/transtation/P1/p2.mp4";
import mp2 from "@/assets/images/transtation/P1/mp2.jpg";
import p3 from "@/assets/images/transtation/P1/p3.jpg";

import p11 from "@/assets/images/transtation/P2/p1.mp4";
import mp11 from "@/assets/images/transtation/P2/mp1.jpg";
import p21 from "@/assets/images/transtation/P2/p2.mp4";
import mp21 from "@/assets/images/transtation/P2/mp2.png";
import p31 from "@/assets/images/transtation/P2/p3.mp4";
import mp3 from "@/assets/images/transtation/P2/mp3.jpg";

import p12 from "@/assets/images/transtation/P3/p1.mp4";
import mp12 from "@/assets/images/transtation/P3/mp1.jpg";
import p22 from "@/assets/images/transtation/P3/p2.mp4";
import mp22 from "@/assets/images/transtation/P3/mp2.jpg";
import p32 from "@/assets/images/transtation/P3/p3.mp4";
import mp32 from "@/assets/images/transtation/P3/mp3.jpg";

import { mixins } from "@/common/scrollmixin.js";
import { look_mixin } from "@/utils/looktime.js";
// 手机
import mobidetiali from "@/components/mobile/mobidetiali";

export default {
  components: {
    transtationdetial,
    mobidetiali,
  },
  mixins: [mixins, look_mixin],
  computed: {
    ...mapGetters(["isMobile"]),
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.itemso = this.videoList[this.$route.params.id - 1].childs;
    setTimeout(() => {
      this.$refs.deo0[0].videoplay();
    }, 500);
    // console.log(this.$route.params.id);

    this.$nextTick();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  data() {
    return {
      scroll: 0,
      itemso: [],
      videoList: [
        {
          id: 1,
          childs: [
            {
              id: 1,
              title: "独山县卓申光伏电站",
              title1: "项目概览",
              desc: "",
              src: p1,
              isvideo: true,
              pages: true,
              runurl: "/transtationdetial",
              img: mp1,
              detial: "",
              list: [
                {
                  id: 3,
                  name: "装机总量约200兆瓦",
                  font: "iconfont icon-fadianzhan",
                },
                {
                  id: 4,
                  name: "年发电量1.9亿千瓦时",
                  font: "iconfont icon-xiaofeng",
                },
                {
                  id: 2,
                  name: "占地面积约5500亩",
                  font: "iconfont icon-jihuatudichubeijihua",
                },
                { id: 1, name: "贵州省独山县", font: "iconfont icon-dingwei" },
                {
                  id: 5,
                  name: "年减少碳排放量19万吨",
                  font: "iconfont icon-qiti",
                },
                {
                  id: 5,
                  name: "年减少标准煤7.6万吨",
                  font: "iconfont icon-tudui",
                },
              ],
            },
            {
              id: 2,
              title: "",
              title1: "安装与管理",
              desc: "",
              src: p2,
              img: mp2,
              isvideo: true,
              pages: false,
              runurl: "/transtationdetial",
              detial:
                "项目建设中，在光伏面板、支架、接线盒等硬件施工和电压输出、远程管理方面取得了多项关键技术突破。",
            },
            {
              id: 3,
              title: "",
              title1: "生态保护",
              desc: "",
              src: p3,
              isvideo: false,
              pages: false,
              runurl: "/transtationdetial",
              detial:
                "项目结合当地太阳能资源，在荒山荒地开展清洁能源建设，光伏电站的建设替代了燃煤电厂的建设，大大减少对周围环境的污染，起到利用自然可再生资源、节约不可再生的化石能源及保护生态环境的作用。",
            },
          ],
        },
        {
          id: 2,
          childs: [
            {
              id: 1,
              title: "关岭县岗乌卓阳农业光伏电站项目",
              title1: "项目概览",
              desc: "",
              src: p11,
              img: mp11,
              isvideo: true,
              pages: true,
              runurl: "/transtationdetial",
              detial: "",
              list: [
                { id: 1, name: "贵州省关岭县", font: "iconfont icon-dingwei" },

                {
                  id: 3,
                  name: "装机总量200兆瓦",
                  font: "iconfont icon-fadianzhan",
                },
                {
                  id: 4,
                  name: "年发电量2亿千瓦时",
                  font: "iconfont icon-xiaofeng",
                },
                {
                  id: 5,
                  name: "年减少碳排放量20万吨",
                  font: "iconfont icon-qiti",
                },
                {
                  id: 2,
                  name: "占地4900亩",
                  font: "iconfont icon-jihuatudichubeijihua",
                },
                {
                  id: 6,
                  name: "年减少标准煤8万吨",
                  font: "iconfont icon-tudui",
                },
              ],
            },
            {
              id: 2,
              title: "",
              title1: "安装与管理",
              desc: "",
              src: p21,
              img: mp21,
              isvideo: true,
              pages: false,
              runurl: "/transtationdetial",
              detial:
                "项目建设中，在光伏面板、支架、接线盒等硬件施工和电压输出、远程管理方面取得了多项关键技术突破；",
            },
            {
              id: 3,
              title: "",
              title1: "生态保护",
              desc: "",
              src: p31,
              img: mp3,
              isvideo: true,
              pages: false,
              runurl: "/transtationdetial",
              detial:
                "该项目是集“光伏发电+水窖+智能蓄灌+生态涵养修复”等多功能于一体的生态农业光伏电站，项目结合当地太阳能资源，在荒山荒地开展清洁能源建设，使贫瘠的土地得到灌溉水源，提升整体生态环境。",
            },
          ],
        },
        {
          id: 3,
          childs: [
            {
              id: 1,
              title: "关岭县卓阳江西坪农业光伏电站",
              title1: "项目概览",
              desc: "",
              src: p12,
              img: mp12,
              isvideo: true,
              pages: true,
              runurl: "/transtationdetial",
              detial: "",
              list: [
                { id: 1, name: "贵州省关岭县", font: "iconfont icon-dingwei" },
                {
                  id: 3,
                  name: "装机总量150兆瓦",
                  font: "iconfont icon-fadianzhan",
                },
                {
                  id: 4,
                  name: "年发电量1.9亿千瓦时",
                  font: "iconfont icon-xiaofeng",
                },
                {
                  id: 2,
                  name: "占地5100亩",
                  font: "iconfont icon-jihuatudichubeijihua",
                },
                {
                  id: 5,
                  name: "年减少碳排放量19万吨",
                  font: "iconfont icon-qiti",
                },
                {
                  id: 6,
                  name: "年减少标准煤7.6万吨",
                  font: "iconfont icon-tudui",
                },
              ],
            },
            {
              id: 2,
              title: "",
              title1: "安装与管理",
              desc: "",
              src: p22,
              img: mp22,
              isvideo: true,
              pages: false,
              runurl: "/transtationdetial",
              detial:
                "项目建设中，在光伏面板、支架、接线盒等硬件施工和电压输出、远程管理方面取得了多项关键技术突破；",
            },
            {
              id: 3,
              title: "",
              title1: "生态保护",
              desc: "",
              src: p32,
              img: mp32,
              isvideo: true,
              pages: false,
              runurl: "/transtationdetial",
              detial:
                "光伏项目建设有利于缓解当地用电压力、缓解节能减排压力，对地方经济、旅游发展有利，项目自身不产生废水、废气、废渣等污染物。在2019年贵州省光伏项目单体建设容量中位列第一。",
            },
          ],
        },
      ],
    };
  },

  methods: {
    video_runpage(e) {
      this.user_btn_click(e.title1, e.runurl, e.btn_name);
    },
    handleScroll() {
      //或者使用document.querySelector('.class或者#id').scrollTop
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      //   console.log("当前位置", this.scroll, "------------", this.$refs.deo2[0]);
      // 第一个屏幕
      if (this.scroll < this.positionList[1].top + 25) {
        this.$refs.deo0[0].videoplay();
        this.$refs.deo1[0].videopause();
        this.$refs.deo2[0].videopause();
      }
      // 第二个屏幕
      if (
        this.scroll < this.positionList[2].top - 150 &&
        this.scroll > this.positionList[1].top - 150
      ) {
        this.$refs.deo0[0].videopause();
        this.$refs.deo1[0].videoplay();
        this.$refs.deo2[0].videopause();
      }
      // 第三个屏幕 this.$refs.deo2
      if (this.scroll > this.positionList[2].top - 150) {
        this.$refs.deo0[0].videopause();
        this.$refs.deo1[0].videopause();
        this.$refs.deo2[0].videoplay();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>